import { Box, Typography, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { getBlogAPI } from '@/Configuration/API';
import { xml2json } from 'xml-js';
import { getTime } from '@/Configuration/Service';
import Image from 'next/image';
import axios from 'axios';
const LatestBlogs = ({ blogs }: any) => {
  const DomParser = require('dom-parser');
  const [blogArray, setBlogArray] = useState<any>();

  useEffect(() => {
    setBlogArray(blogs);
  }, []);

  const getImageUrl = (data: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    const imgElement = doc.querySelector('img');
    return String(imgElement?.getAttribute('src'));
  };

  return (
    <Box className="latest-blogs section-top-gapping">
      <Box className="container">
        <Typography
          component={'h3'}
          variant="h3"
          className="block-title fs-24 font-semibold"
          mb={1}
        >
          Latest Blog
        </Typography>
        <Box className="blog-items-wrap d-inline-block">
          {blogArray?.map(
            (
              res: {
                description: { _cdata: string };
                link: { _text: string };
                pubDate: { _text: string };
                title: { _text: string };
              },
              i: number,
            ) => {
              return (
                <Box
                  key={i}
                  className="blog-card float-none float-md-start w-100 w-md-50"
                  sx={{ display: i <= 3 ? '' : 'none', height: '100%' }}
                >
                  <Box
                    className="blog-card-inner d-flex flex-wrap rounded-4 border border-color-2"
                    sx={{ padding: { md: '15px', xs: 1 } }}
                  >
                    <Box className="img-wrap" sx={{ cursor: 'pointer' }}>
                      <Link
                        href={res?.link?._text}
                        className={`${
                          i === 0 ? 'blogImage' : 'sideBlogImage'
                        } d-block position-relative font-semibold fs-18 text-decoration-none blog-title`}
                        target="_blank"
                      >
                        <Image
                          loading="lazy"
                          width={500}
                          height={500}
                          className="w-100 h-100"
                          src={getImageUrl(String(res.description._cdata))}
                          alt="latest blog images"
                          style={{
                            objectFit: 'fill',
                          }}
                        />
                      </Link>
                    </Box>
                    <Box className="blog-detail">
                      <Typography
                        className="fs-14 font-semibold"
                        color={'#8a8a98'}
                        noWrap
                      >
                        {getTime(res?.pubDate?._text)}
                      </Typography>
                      <Link
                        href={res?.link?._text}
                        target="_blank"
                        className="font-semibold fs-18 text-decoration-none blog-title"
                      >
                        {res?.title?._text}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              );
            },
          )}
        </Box>
        <Box className="blog-items-wrap d-inline-block"></Box>
        <Box className="text-center" mt={3}>
          <Link
            className="font-semibold text-decoration-underline text-blue"
            href="/blog/"
            target="_blank"
          >
            View More
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default LatestBlogs;
