import Head from 'next/head';
import { MetaDescriptionInterface } from '../Interface/interface';
const MetaDescription = (props: MetaDescriptionInterface) => {
  const {
    title,
    description,
    ogUrl,
    ogType,
    imageURL,
    robots,
    keywords,
    canonical,
    isNotForHomePage,
    isForSingleDevicePage,
  } = props;
  let countryAsPath =
    props.country && !['us', 'eu'].includes(props.country)
      ? `${props.country}/`
      : '';
  const canonicalUrl = props.canonical?.split(
    `https://www.techspecs.info/${countryAsPath}`,
  );
  return (
    <Head>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {!isForSingleDevicePage && <meta property="og:url" content={ogUrl} />}
      <meta property="og:type" content={ogType ? ogType : 'product'} />
      <meta property="og:locale" content="en" />
      <meta property="og:site_name" content="TechSpecs" />
      <meta name="robots" content={robots} />
      {isForSingleDevicePage ? (
        <meta property="og:image" content={imageURL} />
      ) : (
        <meta name="og:image" content={imageURL} />
      )}
      {!isNotForHomePage && <meta name="og:site_name" content="TechSpecs" />}
      {!isNotForHomePage && <meta property="og:image:width" content="1600" />}
      {!isNotForHomePage && <meta name="og:image:height" content="837" />}
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@TechSpecs" />
      <meta name="twitter:image" content={imageURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <link rel="canonical" href={canonical} />
    </Head>
  );
};
export default MetaDescription;
