import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import ProductList from './productList';
import { pagination } from '../Interface/interface';
import PaginationBlock from '../CommonComponent/pagination';
import { Context } from '@/pages/_app';
import Link from 'next/link';
import { scrollToElement } from '@/Configuration/Service';

const ProductBlock = (props: {
  productDataArray: {
    slug: string;
    main_image: { url: string; alternativeText: string | null };
    price: {
      [key: string]: number | null;
    };
    url: string | null;
    device_name: string | null;
    device_type: string | null;
    spec_score: string | null;
    tags: string[];
  }[];
  columnCount: boolean;
  sectionTitle: string;
  viewMoreLink: boolean;
  pagination: Boolean;
  category: string;
  path: String;
  queryParam?: {
    pageSize: number;
    page: number;
    brand_name: string;
    internal_storage: string;
    battery_capacity: string;
    display_screen_size: string;
    primary_camera: string;
    secondary_camera: string;
    chipset_processor: string;
    resolution_type: string;
  };
  setQueryParams?: Function | undefined;
  paginationData: pagination | undefined;
}) => {
  const { setIsChange, isApiCall, currencyDropDownValue, scrollTargetRef } =
    useContext(Context);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setIsChange(true);
    scrollToElement(scrollTargetRef);
    if (props.setQueryParams !== undefined) {
      props.setQueryParams(
        (prev: {
          pageSize: number;
          page: number;
          brand_name: string;
          internal_storage: string;
          battery_capacity: string;
          display_screen_size: string;
          primary_camera: string;
          secondary_camera: string;
          chipset_processor: string;
          resolution_type: string;
        }) => ({
          ...prev,
          page: value,
        }),
      );
    }
  };
  return (
    <div style={{ position: 'relative' }}>
      <Box className="product-list ">
        <Typography
          className="home-section-title fs-24 font-semibold text-center"
          py={1.5}
          mb={{ xs: 5, sm: 5 }}
          display={props.sectionTitle === '' ? 'none' : 'block'}
        >
          {props.sectionTitle}
        </Typography>
        <Box className="product-list-wrap text-center">
          {props?.productDataArray?.length ? (
            <ProductList
              productDataArray={props?.productDataArray}
              columnCount={props?.columnCount}
              category={props?.category}
              viewMoreLink={props?.viewMoreLink}
            />
          ) : (
            <div>No data found</div>
          )}
        </Box>
        {isApiCall ? (
          ''
        ) : (
          <>
            <Box
              className="text-center"
              mt={{ xs: 2, sm: 4, md: 5 }}
              display={props.viewMoreLink ? 'block' : 'none'}
            >
              <Link
                href={`/${props.path}`}
                className="text-blue font-semibold text-decoration-underline"
              >
                View More
              </Link>
            </Box>
            {props?.productDataArray?.length ? (
              <PaginationBlock
                paginationData={props.paginationData}
                handleChange={handleChange}
                pagination={props.pagination}
              />
            ) : (
              ''
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default ProductBlock;
