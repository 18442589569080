import { getHexCodeFromTagItem } from '@/Configuration/Service';
import { Typography } from '@mui/material';
import React from 'react';

const Tags = ({ singleDeviceData, borderRadius }: any) => {
  return (
    singleDeviceData?.tags &&
    singleDeviceData?.tags.length > 0 &&
    singleDeviceData?.tags
      .filter((tagItem: any) => tagItem !== 'rumored_badge')
      .map((tagItem: string, index: number) => {
        const tagNameAndColor = getHexCodeFromTagItem(tagItem);
        return tagNameAndColor ? (
          <Typography
            key={`${tagItem}-${index}`}
            className={`text-white text-center d-inline-block ${borderRadius}`}
            bgcolor={tagNameAndColor.color}
            fontWeight={500}
            sx={{
              padding: '3px 5px',
            }}
          >
            {tagNameAndColor.tagName}
          </Typography>
        ) : null;
      })
  );
};

export default Tags;
