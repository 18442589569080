import { Box, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import Image from 'next/image';
import defaultSmartPhone from '../Assets/Images/defaultSmartphone.jpg';
import { Context } from '@/pages/_app';
import Skeleton from '@mui/material/Skeleton';
import Link from 'next/link';
import PriceComponent from './PriceComponent';
import Tags from './Tags';
const ProductList = (props: {
  productDataArray: {
    slug: string;
    main_image: { url: string; alternativeText: string | null };
    url: string | null;
    device_name: string | null;
    device_type: string | null;
    price: {
      [key: string]: number | null;
    };
    spec_score: string | null;
    tags: string[];
  }[];
  columnCount: boolean;
  category: string;
  viewMoreLink: boolean;
}) => {
  const { isApiCall, currencyDropDownValue } = useContext(Context);
  return (
    <Box>
      {isApiCall ? (
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {Array.from(new Array(12)).map((res, i) => (
            <Grid
              key={i}
              item
              md={props.columnCount ? 3 : 4}
              sm={4}
              xs={6}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box
                className="inner-wrap"
                sx={{
                  pb: 0,
                  pt: { sm: 2, xs: 1 },
                  px: { sm: 1, xs: 0.5 },
                  display: i >= 8 && props.viewMoreLink ? 'none' : '',
                }}
              >
                <Box className="product-image">
                  <Skeleton variant="rectangular" width={170} height={118} />
                </Box>
                <Box className="product-detail" mt={{ xs: 1, sm: 1.5 }}>
                  <Skeleton width="60%" />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          columnSpacing={1}
          rowSpacing={{ xs: 2.5, sm: 1, md: 2.5, lg: 2.5, xl: 2.5 }}
        >
          {props?.productDataArray?.map(
            (
              res: {
                slug: string;
                main_image: {
                  url: string;
                  alternativeText: string | null | undefined;
                };
                price: {
                  [key: string]: number | null;
                };
                spec_score: string | null;
                url: string | null;
                device_name: string | null;
                device_type: string | null;
                alternative_text?: string | null | undefined;
                tags: string[];
              },
              i: number,
            ) => {
              return (
                <Grid item md={props.columnCount ? 3 : 4} sm={4} xs={6} key={i}>
                  <Box
                    className="inner-wrap position-relative"
                    sx={{
                      pb: 0,
                      pt: { sm: 2, xs: 1 },
                      px: { sm: 1, xs: 0.5 },
                      display: i >= 8 && props.viewMoreLink ? 'none' : '',
                    }}
                  >
                    <Box
                      className="position-absolute"
                      sx={{ zIndex: '99', top: '-18px' }}
                    >
                      <Tags
                        singleDeviceData={res}
                        borderRadius="side-borderRadius"
                      />
                    </Box>
                    <Box className="product-image zoom">
                      <Link
                        className="image-link d-block position-relative text-center overflow-hidden cursor-pointer"
                        href={`/${res.slug}`}
                        target="_blank"
                      >
                        {(res?.main_image?.url !== undefined &&
                          res.main_image.url !== null) ||
                        (res.url !== undefined && res.url !== null) ? (
                          <>
                            <Image
                              loading="lazy"
                              width={200}
                              height={200}
                              src={
                                res?.main_image?.url !== undefined &&
                                res?.main_image?.url !== null
                                  ? `${process.env.API_BASE_URL}${res?.main_image.url}`
                                  : ''
                              }
                              alt={
                                res?.main_image?.alternativeText !==
                                  undefined &&
                                res?.main_image?.alternativeText !== null
                                  ? res?.main_image?.alternativeText
                                  : `${res?.device_name} ${res?.device_type}`
                              }
                              className="home-page-product-image"
                            />
                          </>
                        ) : (
                          <Image
                            loading="lazy"
                            src={defaultSmartPhone}
                            alt="product"
                            width={0}
                            height={0}
                            sizes="100vw"
                            className="home-page-product-image"
                          />
                        )}
                      </Link>
                    </Box>
                    <Box className="product-detail" mt={{ xs: 1, sm: 1.5 }}>
                      <Typography
                        className="product-name fs-18 "
                        lineHeight={{ xs: '18px', sm: '24px' }}
                      >
                        <Link
                          // locale={currencyDropDownValue?.toLowerCase()}
                          href={`/${res.slug}`}
                          className="text-decoration-none"
                          target="_blank"
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          {res?.device_name}
                        </Link>
                      </Typography>
                      {res.spec_score !== null &&
                      res.spec_score !== undefined &&
                      res.spec_score !== 'undefined' ? (
                        <Typography
                          className="product-score-tag text-white rounded-4 text-center d-inline-block"
                          bgcolor={'#72A02B'}
                          fontWeight={500}
                          sx={{
                            lineHeight: { xs: '13px', sm: '20px' },
                            fontSize: { xs: '10px !important', sm: '14px' },
                            padding: {
                              xs: '3px 5px',
                              sm: '3px 10px',
                            },
                          }}
                        >
                          {`${parseInt(res.spec_score)}% SPEC SCORE`}
                        </Typography>
                      ) : (
                        ''
                      )}
                      <Typography className="product-price text-blue font-semibold ">
                        <PriceComponent price={res.price} />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            },
          )}
        </Grid>
      )}
    </Box>
  );
};

export default ProductList;
